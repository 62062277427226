import { Icon } from '@iconify/react';
import appleFilled from '@iconify/icons-ant-design/apple-filled';
// material
import SearchIcon from '@mui/icons-material/Search';
import PaymentsIcon from '@mui/icons-material/Payments';
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
// utils
import { fShortenNumber } from './formatNumber';
import { React, useState, useEffect } from 'react';
import apirequest from '../../lib/settings';
import {baseurl} from '../../lib/settings';
import axios from 'axios';


import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.info.darker,
  backgroundColor: theme.palette.info.lighter
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.info.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.info.dark, 0)} 0%, ${alpha(
    theme.palette.info.dark,
    0.24
  )} 100%)`
}));

// ----------------------------------------------------------------------


export default function AppNewUsers() {
  
  const [rows, setRows] = useState([]);

  useEffect(() => {
    baseurl.get('api/auth/ussd/').then((response) => {
      setRows(response.data)
    });
  }, []);

  

  
  const TOTAL = rows.length;
  //armazena o valor da variavel TOTAL
  localStorage.setItem("consultas", TOTAL)

  return (
    <div>
      <RootStyle>
        <IconWrapperStyle>
          <SearchIcon/>
        </IconWrapperStyle>
          <Typography variant="h3"> {fShortenNumber(TOTAL)} </Typography>
          <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
          Consultas
        </Typography>
    </RootStyle>
    </div>

  );
}
