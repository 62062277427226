import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { useTheme, styled } from '@mui/material/styles';
import { Card, CardHeader } from '@mui/material';

import { useState, useEffect } from 'react';
// utils
import { fNumber } from './formatNumber';
//
import { BaseOptionChart } from '../Dashboard/charts';
import {baseurl} from '../../lib/settings';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 372;
const LEGEND_HEIGHT = 72;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible'
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`
  } 
}));

// ----------------------------------------------------------------------




export default function AppCurrentVisits() {
  useEffect(() => {
    //getConsultas();
    getRequisicoes();
  }, []);

  const theme = useTheme();
  const [requisicoes, setRequisicoes] = useState([]);
  var contMovitel = 0;
  var contVodacom = 0;
  var contTmcel = 0;
  const getRequisicoes = async () => {
    baseurl.get("api/auth/all_requests").then((response) => {
      setRequisicoes(response.data);
    });
  };

  {
    requisicoes.map((requisi, index) => {
      if (requisi.phone.substring(0, 5) == "25887" || requisi.phone.substring(0, 5) == "25886" ) {
        contMovitel++;
      } else if (requisi.phone.substring(0, 5) == "25884") {
        contVodacom++;
      } else if (requisi.phone.substring(0, 5) == "25882") {
        contTmcel++;
      }
    });
  }

  let a = contMovitel;
  let b = contVodacom;
  let c = contTmcel;
  const CHART_DATA = [a, b, c];

  const chartOptions = merge(BaseOptionChart(), {
    colors: [
      theme.palette.primary.main,
      theme.palette.info.main,
      theme.palette.warning.main,
      theme.palette.error.main
    ],
    labels: ['Movitel', 'Vodacom', 'Tmcel'],
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: 'center' },
    dataLabels: { enabled: true, dropShadow: { enabled: false } },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => `#${seriesName}`
        }
      }
    },
    plotOptions: {
      pie: { donut: { labels: { show: false } } }
    }
  });

  return (
    <Card>
      <CardHeader title="Visitas por Operadora" />
      <ChartWrapperStyle dir="ltr">
        <ReactApexChart type="pie" series={CHART_DATA} options={chartOptions} height={310} />
      </ChartWrapperStyle>
    </Card>
  );
}
