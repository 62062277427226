import * as React from "react";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { withStyles } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import { AutoSizer, Column, Table } from "react-virtualized";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { baseurl } from "../../lib/settings";

//import * as React from 'react';
import { DataGrid } from "@mui/x-data-grid";


//novas importacoesx
// import MaterialTable from "material-table";

const styles = (theme) => ({
  flexContainer: {
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
  },
  table: {
    "& .ReactVirtualized__Table__headerRow": {
      ...(theme.direction === "rtl" && {
        paddingLeft: "0 !important",
      }),
      ...(theme.direction !== "rtl" && {
        paddingRight: undefined,
      }),
    },
  },
  tableRow: {
    cursor: "pointer",
  },
  tableRowHover: {
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
  },
  tableCell: {
    flex: 1,
  },
  noClick: {
    cursor: "initial",
  },
});

class MuiVirtualizedTable extends React.PureComponent {
  static defaultProps = {
    headerHeight: 48,
    rowHeight: 48,
  };

  getRowClassName = ({ index }) => {
    const { classes, onRowClick } = this.props;

    return clsx(classes.tableRow, classes.flexContainer, {
      [classes.tableRowHover]: index !== -1 && onRowClick != null,
    });
  };

  cellRenderer = ({ cellData, columnIndex }) => {
    const { columns, classes, rowHeight, onRowClick } = this.props;
    return (
      <TableCell
        component="div"
        className={clsx(classes.tableCell, classes.flexContainer, {
          [classes.noClick]: onRowClick == null,
        })}
        variant="body"
        style={{ height: rowHeight }}
        align={
          (columnIndex != null && columns[columnIndex].numeric) || false
            ? "right"
            : "left"
        }
      >
        {cellData}
      </TableCell>
    );
  };

  headerRenderer = ({ label, columnIndex }) => {
    const { headerHeight, columns, classes } = this.props;

    return (
      <TableCell
        component="div"
        className={clsx(
          classes.tableCell,
          classes.flexContainer,
          classes.noClick
        )}
        variant="head"
        style={{ height: headerHeight }}
        align={columns[columnIndex].numeric || false ? "right" : "left"}
      >
        <span>{label}</span>
      </TableCell>
    );
  };

  render() {
    const { classes, columns, rowHeight, headerHeight, ...tableProps } =
      this.props;
    return (
      <AutoSizer>
        {({ height, width }) => (
          <Table
            height={height}
            width={width}
            rowHeight={rowHeight}
            gridStyle={{
              direction: "inherit",
            }}
            headerHeight={headerHeight}
            className={classes.table}
            {...tableProps}
            rowClassName={this.getRowClassName}
          >
            {columns.map(({ dataKey, ...other }, index) => {
              return (
                <Column
                  key={dataKey}
                  headerRenderer={(headerProps) =>
                    this.headerRenderer({
                      ...headerProps,
                      columnIndex: index,
                    })
                  }
                  className={classes.flexContainer}
                  cellRenderer={this.cellRenderer}
                  dataKey={dataKey}
                  {...other}
                />
              );
            })}
          </Table>
        )}
      </AutoSizer>
    );
  }
}

MuiVirtualizedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataKey: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      numeric: PropTypes.bool,
      width: PropTypes.number.isRequired,
    })
  ).isRequired,
  headerHeight: PropTypes.number,
  onRowClick: PropTypes.func,
  rowHeight: PropTypes.number,
};

const defaultTheme = createTheme();
const VirtualizedTable = withStyles(styles, { defaultTheme })(
  MuiVirtualizedTable
);

// ---

const sample = [
  ["Frozen yoghurt", 159, 6.0, 24, 4.0],
  ["Ice cream sandwich", 237, 9.0, 37, 4.3],
  ["Eclair", 262, 16.0, 24, 6.0],
  ["Cupcake", 305, 3.7, 67, 4.3],
  ["Gingerbread", 356, 16.0, 49, 3.9],
];

function createData(id, dessert, calories, fat, carbs, protein) {
  return { id, dessert, calories, fat, carbs, protein };
}

const rows = [];

for (let i = 0; i < 200; i += 1) {
  const randomSelection = sample[Math.floor(Math.random() * sample.length)];
  rows.push(createData(i, ...randomSelection));
}

export default function AppWebsiteVisits() {
  const history = useHistory();

  const [rows, setRows] = useState([]);
  const [requisicoes, setRequisicoes] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [error, setError] = useState();
  const [loading, setIsloading] = useState(true);
  const [searchText, setSearchText] = React.useState("");
  const [ros, setRos] = React.useState(rows.ros);
  const [valor, setValor] = useState();

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'phone', headerName: 'Celular', width: 130 },
    { field: 'date', headerName: 'Data', width: 140 },
    { field: 'trasantion_type', headerName: 'Operacao', width: 370 },
  ];

  // const getConsultas = async () => {
  //   baseurl.get("api/auth/ussd").then((response) => {
  //     setRows(response.data);
  //   });
  // };

  const getRequisicoes = async () => {
    baseurl.get("api/auth/all_requests").then((response) => {
      setRequisicoes(response.data);
    });
  };

  // {
  //   requisicoes.map((requisi, index) =>
  //   );
  // }

  // const TOTAL = requisicoes.length;

  useEffect(() => {
    //getConsultas();
    getRequisicoes();
  }, []);
  return (
    <>
      <div style={{ height: 400, width: "100%" }}>
     
        <DataGrid
          rows={requisicoes}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection
        />
      </div>

      
      {/* <div>
        <div>
          <Paper elevation={8} style={{ height: 500, width: "100%" }}>
            <br />
            <h3>Controle de Operações Efectuadas</h3>
            <VirtualizedTable
              rowCount={rows.length}
              rowGetter={({ index }) => rows[index]}
              columns={[
                {
                  width: 50,
                  label: "ID",
                  dataKey: "id",
                },
                {
                  width: 160,
                  label: "Celular",
                  dataKey: "phone",
                  numeric: true,
                },
                {
                  width: 150,
                  label: "Data",
                  dataKey: "date",
                  numeric: true,
                },
                {
                  width: 300,
                  label: "Tipo de Transação",
                  dataKey: "trasantion_type",
                  numeric: true,
                },
              ]}
            />
          </Paper>
        </div>
        <div>
          <Paper elevation={8} style={{ height: 500, width: "100%" }}>
            <br />
            <h3>Controle de Requisições Efectuadas</h3>
            <VirtualizedTable
              rowCount={requisicoes.length}
              rowGetter={({ index }) => requisicoes[index]}
              columns={[
                {
                  width: 50,
                  label: "ID",
                  dataKey: "id",
                },
                {
                  width: 160,
                  label: "Celular",
                  dataKey: "phone",
                  numeric: true,
                },
                {
                  width: 150,
                  label: "Data",
                  dataKey: "date",
                  numeric: true,
                },
                {
                  width: 300,
                  label: "Tipo de Transação",
                  dataKey: "trasantion_type",
                  numeric: true,
                },
              ]}
            />
          </Paper>
        </div>
      </div> */}

      <br />
      <br />
    </>
  );
}
