import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Employee from '../form/pics/Employee.svg';
import Training from './pics/svnewEmployee.svg'
import Grid from '@mui/material/Grid';
import { useHistory } from 'react-router-dom'

export default function ActionAreaCard() {

  const history = useHistory();
  function onSubmitEmployee() {
    history.push('/add/employee')
  }

  function onSubmitTraining() {
    history.push('/add/formation')
  }
  return (

    <Grid
      margin={5}
      marginLeft={25}
      justifyContent="center"
      container spacing={2}>
      <Grid item xs={3}>

        <Card
          onClick={onSubmitEmployee}
          sx={{ maxWidth: 345 }}>
          <Grid container spacing={-1}>

            <CardActionArea>
              <CardMedia
                component="img"
                height="250"
                image={Employee}
                alt="employee"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  New Employee
                </Typography>

              </CardContent>
            </CardActionArea></Grid>
        </Card>


      </Grid>
      <Grid item xs={7}>

        <Card
          onClick={onSubmitTraining}
          sx={{ maxWidth: 345 }}>
          <CardActionArea>
            <CardMedia
              component="img"
              height="250"
              image={Training}
              alt="training"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Add Training
              </Typography>

            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>

    </Grid>



  );
}