import { CircularProgress } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import logo from './components/logo/Rlogo.png';
import { Button } from '@material-ui/core';
import photo from './components/perfil/pf.jpg'
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import './components/confirmation.css';
import {baseurl} from '../lib/settings';

export function Confidentification() {

  const [tableData, setTableData] = useState([]);
  const {id} = useParams();
  const [src, setSrc] = useState("");
  const [isloading, setIsloading] = useState(true);
  const [dataimg, setDataimg] = useState([]);
  const [error, setError] = useState();

  const handleClick = (id) => {
    /*const id = useParams*/
    History.push(`/cracha/${id}`);
  }

 
  const isLandscape = () => window.matchMedia('(orientation:landscape)').matches,
          [orientation, setOrientation] = useState(isLandscape() ? 'landscape' : 'portrait'),
          onWindowResize = () => {              
            clearTimeout(window.resizeLag)
            window.resizeLag = setTimeout(() => {
              delete window.resizeLag                       
              setOrientation(isLandscape() ? 'landscape' : 'portrait')
            }, 200)
          }

    useEffect(() => (
      onWindowResize(),
      window.addEventListener('resize', onWindowResize),
      () => window.removeEventListener('resize', onWindowResize)
    ),[])



    // useEffect(() => {
    //   baseurl.get('/api/employeer/' + id).then((data) => {
    //     setDataimg(data);
    //     setIsloading(false);
    //   })
    //     .catch((error) => setError(error));
    // }, []);

    useEffect(() => {
      baseurl.get('/api/formacao/' + id).then((response) => {
        setTableData(response.data);
        setIsloading(false);
      })
        .catch((error) => setError(error));
    }, []);

   
  
  if (error)
    return (
      <div className='App-text'>
        <h2>Sorry, it was not possible to confirm the data.</h2>
        <h3>Try again</h3>
        <Button variant='contained' onClick={(event) => {
          handleClick(event)
        }}>Try again</Button>
      </div>
    )
  

  if (isloading){
      return (
        <div  style={{
            marginTop: 150,
            position: "absolute",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            width: "100vw"
          }}>
        <CircularProgress />
        </div>
      )
  }
  


  var fora;
  const dateControl = tableData.map((date, index) => {
    let today = new Date();
    let validade = new Date(tableData[index].validity);
    let ms1 = today.getTime();
    let ms2 = validade.getTime();
    if (ms1 > ms2) {
      fora = 1;
      return (
        <div className="itemleft">
          <p >
            {tableData[index].formacao}
          </p>
          <p style={{ color: '#ff0000', fontWeight: 'bold', fontSize: '12.5px' }}>
            {tableData[index].validity}
          </p>
        </div>
      );
    } else {
      fora = 2;
      return (
        <div className="itemleft">
          <p>{tableData[index].formacao}</p>
          <p>{tableData[index].validity}</p>
        </div>
      );
    }
  });



 
  return (
    <div >
      
       <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert onClose={() => {}}> Verificado 
        </Alert>
        
       </Stack>
       
      <div style={{
      marginTop: 50,
      position: "absolute",
      display: "flex",
      justifyContent: "center",
      width: '96%'
      
    }} id='content' className="container">
      
      <header>
        <div className='name'>
          <h2>{tableData.length!==0 && tableData[0].trabalhador.name}</h2>
        </div>
          <div>
                <div className="headerText">
                <h3>Expiration Date:</h3>
                </div>
                <img src={logo} alt="Logo" />
          </div>
        
      </header>
      <main>
        <div >
        <div className='itemleft'>
            <p>Medical Exams</p>
            <p>{dataimg.validate
              
              }
              </p>
          </div>
          {dateControl}
        </div>
       
      </main>
      <footer className='footer'>
      <p className="compName2">TWIGG</p>
      <p className="companyName">Exploration & Mining, Lda</p>
      <img src={dataimg.image || photo} alt="Photo" />
      </footer>
      <div className='footercont'>
             
          </div>
          
      </div>
       <div className='compont'>
       
       </div>
    </div>
  );
}