import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { SingleCracha } from './SingleCracha';
import styles from './multiCracha.module.css';
import { Button } from '@material-ui/core';
import jsPDF from 'jspdf';
import PictureAsPdfIcon from '@mui/icons-material/Download';

export function MultiCrachas() {
  const location = useLocation();
  const ids = location.state.ids;
  const [dataimg, setDataimg] = useState([]);


  const pdfGenerate = () => {
    var doc = new jsPDF('l', 'px', 'a4', 'false');
    doc.html(document.querySelector('#content'), {
      callback: function (pdf) {
        pdf.save('Cracha.pdf');
      },
    });
  };

  return (
    <div>
       
      <div id="content" className={styles.container}>
      
      {ids.map((id) => {
        return <SingleCracha key={id} id={id} />;
      })}
    </div>
    </div>
   
  );
}
