import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import LoadingButton from '@mui/lab/LoadingButton';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { baseurl } from "../../lib/settings";
import { Alert } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import logo from "../components/logo/Rlogo.png"
import { Formik } from "formik";
import * as yup from 'yup';
const theme = createTheme();

const VALIDATION_SCHEMA = yup.object().shape({
  username: yup.string().required('Email cannot be empty.'),
  password: yup.string().required('Password cannot be empty.'),
})

export default function Login() {
  const [isError, setIsError] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [authorization, setAuthorization] = useState();
  const history = useHistory();
  const [initialState, setInitialState] = useState({
    username: '',
    password: '',
  })

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  // function checkAutorization() {
  //   baseurl.get("api/auth/user_authentication/").then((response) => {
  //     if (response.status === 200) {
  //       history.push("/update/badge/list/employee");
  //     } else {
  //       history.push("/list/employee");
  //     }
  //   }).catch((error) => {
  //     if (error.response.status === 400) {
  //       history.push("/list/employee");

  //     }
  //   })
  // }

  async function handleSubmit(event) {
    console.log("Show password", event)
    handleToggle();
    setIsLoading(true);
    localStorage.removeItem("token");
    const credentials = {
      username: event.username,
      password: event.password,
    };

    //logout()

    const resp = await baseurl
      .post("/api/login/", credentials)
      .then((response) => {
        setAuthorization(response.data);
        localStorage.setItem("token", JSON.stringify(response.data.token));

        // var username = localStorage.getItem('user')
        // var tuser = JSON.parse(username)
        // var user = tuser.user_name


        if (response.data.token) {
          // checkAutorization()
          history.push("/list/employee");
        }
        setIsLoading(false);
      })

      .catch((error) => {
        handleClose();
        // alert("Password ou Username inválido");
        setIsError(true);
        setIsLoading(false);
      });
    //setIsLoading(false)
  }

  const paperStyle = {
    padding: 1,
    height: 550,
    width: 500,
    margin: "60px auto",
  };

  return (
    <ThemeProvider theme={theme}>
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}

      <Paper elevation={2} style={paperStyle}>

        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, height: 50, width: 50 }}>
              <img src={logo} height={30} />
            </Avatar>{" "}
            {/**<img src={Logo}/> */}
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <br />
            {isError && (
              <Alert severity="warning" onClose={() => {
                setIsError(false);
              }}>
                Your credentials are incorrect.
              </Alert>
            )}
            <Box
              sx={{ mt: 1 }}
            >
              <Formik
                initialValues={{ ...initialState }}
                validationSchema={VALIDATION_SCHEMA}
                validateOnChange={true}
                validateOnBlur={false}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset
                }) => {
                  const { username, password } = values;
                  return (
                    <>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="username"
                        label="Username"
                        name="username"
                        value={username}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors.username}
                        helperText={errors.username}
                        autoFocus
                      />

                      <TextField
                        margin="normal"
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        value={password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors.password}
                        helperText={errors.password}
                      />



                      {/* <Button
                onClick={handleSubmit}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button> */}
                      <LoadingButton
                        loading={isloading}
                        onClick={handleSubmit}
                        fullWidth
                        variant="contained"

                      >
                        Sign In
                      </LoadingButton>
                    </>
                  )
                }}
              </Formik>
              {/* <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                  </Link>
                </Grid>
              </Grid> */}
            </Box>
          </Box>
        </Container>
      </Paper>
    </ThemeProvider>
  );
}
