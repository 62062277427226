import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import baseurl from "./../../lib/settings";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { CircularProgress } from "@material-ui/core";
import Backdrop from "@mui/material/Backdrop";

const theme = createTheme();

function Enter() {
    useEffect(() => {
        handleSubmit();
    }, []);
    const [isloading, setIsLoading] = useState(false);
    const [authorization, setAuthorization] = useState();
    const history = useHistory();

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };

    async function handleSubmit() {
        handleToggle();
        localStorage.removeItem("token");
        //setIsLoading(true)
        // event.preventDefault();
        const data = new FormData();

        const credentials = {
            username: "Malingas",
            password: "Wsxhfsa12ey",
        };

        const resp = await baseurl
            .post("/api/login/", credentials)
            .then((response) => {
                setAuthorization(response.data);
                localStorage.setItem("token", JSON.stringify(response.data.token));
                if (response.data.token) {
                    history.push("/list/employee");
                } else {
                }
            })
            .catch((error) => {
                handleClose();
                alert("Password ou Username invalido");
            });
    }

    const paperStyle = {
        padding: 1,
        height: 550,
        width: 500,
        margin: "60px auto",
    };

    return (
        <div>
            <ThemeProvider theme={theme}>
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                    onClick={handleClose}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Paper elevation={6} style={paperStyle}>
                    <div className="loading">{isloading && <CircularProgress />}</div>

                    <Container component="main" maxWidth="xs">
                        <CssBaseline />
                        <Box
                            sx={{
                                marginTop: 8,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                                <LockOutlinedIcon />
                            </Avatar>{" "}
                            {/**<img src={Logo}/> */}
                            <Box
                                component="form"
                                onSubmit={handleSubmit}
                                noValidate
                                sx={{ mt: 1 }}
                            >
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Sign In
                                </Button>
                                <Grid container>
                                    <Grid item xs></Grid>
                                    <Grid item>
                                        <Link href="#" variant="body2">
                                            {/*"Não sabe qual conta usar?"*/}
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Container>
                </Paper>
            </ThemeProvider>
        </div>
    );
}

export default Enter;
