import React, { useState, useEffect } from 'react'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import ThemeConfig from './theme'
import AppWeeklySales from './AppWeeklySales';
import AppNewUsers from './AppNewUsers';
import AppItemOrders from './AppItemOrders';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useHistory } from 'react-router';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Avatar from '@mui/material/Avatar'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';




function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="#">
        </Link>{' SPERANZA '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  } 


function Verifications() {
    const history = useHistory();
    const[code, setCode] = useState();
    const[verify, setVerify] =  useState();
    
    useEffect(() => {
        verification()
    }, [verify])
    
    function onSubmit() {
        setVerify(code)
    }

    function verification () {
        if(code == '1423'){
            history.push('/dashboard')
        }else{
             //alert('Credencias Invalidas')
        }
    }

    const paperStyle={
        padding :20,
        height:'90vh',
        width:500, 
        margin:"20px auto"
      }

    const theme = createTheme();
  
    return (
        <>
         <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
           Autentication
          </Typography>
        </Box>
            <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextField
            onChange={((e) => setCode(e.target.value))} 
            id="outlined-basic" 
            label="PIN" 
            variant="outlined" 
            type="password"
            margin="normal"
            required
            fullWidth

            />
          <br/>
          <br/>
            <Button onClick={onSubmit} variant="contained"
            fullWidth
            >Submit</Button>
            </Box>
            <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>
        </>
    );
}

export default Verifications
