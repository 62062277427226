// material
import GroupsIcon from '@mui/icons-material/Groups';
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
// utils
import { fShortenNumber } from './formatNumber';
import PaymentsIcon from '@mui/icons-material/Payments';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.warning.darker,
  backgroundColor: theme.palette.warning.lighter
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.warning.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.warning.dark, 0)} 0%, ${alpha(
    theme.palette.warning.dark,
    0.24
  )} 100%)`
}));

// ----------------------------------------------------------------------
const i = Number(localStorage.getItem("consultas"))
const j = Number(localStorage.getItem("msisdn"))


const soma = i * 3
const TOTAL = soma;


export default function AppItemOrders() {
  return (
    <RootStyle>
      <IconWrapperStyle>
      <PaymentsIcon/>
        {/* <Icon icon={<GroupsIcon/>} width={24} height={24} /> */}
      </IconWrapperStyle>
      <Typography variant="h3">{fShortenNumber(TOTAL)} MT</Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        Valor total
      </Typography>
    </RootStyle>
    
  );
}
