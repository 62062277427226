import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { DatePicker } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { baseurl } from '../lib/settings';
import Moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import { Autocomplete } from '@material-ui/core';
import { useHistory } from 'react-router';
import { CrachaCheck } from '../pages/components/crachas/crachaCheck';
import './formacao.css'
import CrachaOvierview from '../pages/components/crachas/cracha-overview';
import { LoadingButton } from '@mui/lab';
import { FormControlLabel, InputLabel, Typography } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const names = [
    'Folklift Operation',
    'Folklift Operation VOC',
    'Light Duty Vehicle',
    'Light Duty Vehicle VOC',
    'Heavy Duty Vehicle',
    'Heavy Duty Vehicle VOC',
    'Telehandler Operation',
    "JHA",
    'Work at Height',
    'LOTO',
    'Confined Spaces',
    'Confined Spaces VOC',
    'Skid Steer Loader VOC',
    'Skid Steer Training',
    'Cherry Picker VOC',
    'Full Induction',
    '2h Induction',
    'Overhead Crane VOC',
    'Permit-to-Work',
    'Tractor',
    'Tractor VOC',
    'Chemical Products',
    "Crane",
    "Crane VOC",
    "Picky and carry crane",
    "Picky and carry crane VOC",
    "Concrete Mixer",
    "Concrete Mixer VOC",
    "Excavation Training",
    "Hot Work",
    "Concret block",
    "HCH",
    "MCU",
    "Permit Issuer",
    "Permit Issuer VOC",
    "Basic Fire Fighting",
    "Basic Fire Fighting VOC",
    "Gym Induction",
    "Gym Induction VOC"

];

function getStyles(names, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(names) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}


export default function AddFormation() {
    const theme = useTheme();
    const [personName, setPersonName] = useState('');
    const [value, setValue] = useState(new Date());
    const history = useHistory()
    const [isloading, setIsLoading] = useState(false);
    const [saveIsloading, setSaveIsLoading] = useState(false);
    const [error, setError] = useState();
    const [date, setDate] = useState();
    const [name, setName] = useState();
    const [status, setStatus] = useState();
    const [listemployee, setListEmployee] = useState([]);
    const [badge, setBadge] = useState(false);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            value
        );
    };

    const defaultProps = {
        options: listemployee,
        getOptionLabel: (option) => option.name,
    };


    useEffect(() => {
        setIsLoading(true);
        baseurl.get('/api/auth/employeer/').then((response) => {
            setListEmployee(response.data);
        }).catch((error) => setError(error));
        setIsLoading(false);
    }, []);

    async function handleSubmit() {
        setSaveIsLoading(true)
        let date = Moment(value).format('YYYY-MM-DD')

        const data = {
            formacao: personName,
            trabalhador: name,
            validity: date
        }
        const r = await baseurl.post('/api/auth/formadd/', data).then((response) => {
        });

        setSaveIsLoading(false)
        history.push('/list/employee')
        window.alert('Training added')
    }

    useEffect(() => {
        console.log("FINAL STEP TO UPDATE BADGE")
        setStatus(true)
        setBadge(true)
    }, [name])



    function checkValue(v) {
        console.log("Get badge", v)
        setStatus(true)
        setName(v);
        setStatus(false)

    }

    const updateBadge = (value) => {
        console.log("ACTUALIZANDO O BADGE 888888888888 88 88 88 8 8", value)
        setBadge(false)
        // checkValue(value)
        setBadge(true)
        console.log("Done updete badge")

    }


    function crachaChecks() {

        return (
            <div className='cracha'>
                <div className='badges'>{badge && <><CrachaCheck ids={{ name }} /></>}</div>
                <div className='delete-form-base'> <CrachaOvierview id={{ name }} controlBadgeStatus={(e) => updateBadge(e)} /></div>
            </div>

        )
    }

    async function submitions() {
        setIsLoading(true)
        let date = Moment(value).format('YYYY-MM-DD')
        const data = {
            formacao: personName,
            trabalhador: name,
            validity: date
        }

        const r = await baseurl.post('/api/auth/formadd/', data).then((response) => {
            window.alert("Training added")
            checkValue(name)

        })
        setPersonName('');
        setIsLoading(false)
        setStatus(true)

    }

    return (

        <div className='geral'>

            <div className='formacao'>
                <LocalizationProvider dateAdapter={AdapterDateFns}>


                    <h1>Training Program</h1>
                    <FormControl sx={{ m: 1, width: 300 }}>
                        {/* <Typography>Employee</Typography> */}
                        <Autocomplete
                            {...defaultProps}
                            id="controlled-demo"
                            // value={name}
                            onChange={(event, v) => checkValue(v.id)}
                            renderInput={(params) => (
                                <TextField {...params} label="Employee" />
                            )}
                        />
                    </FormControl>
                    <FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id="demo-simple-select-label">Formation</InputLabel>
                        <Select

                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            label="Formation"
                            value={personName}
                            onChange={handleChange}
                            // input={<OutlinedInput />}

                            MenuProps={MenuProps}
                        >

                            {names.map((name) => (
                                <MenuItem
                                    key={name}
                                    value={name}
                                    style={getStyles(names, personName, theme)}
                                >
                                    {name}
                                </MenuItem>
                            ))}
                        </Select>
                        {/* <br /> */}
                    </FormControl>
                    <FormControl sx={{ m: 1, width: 300 }}>
                        <Stack spacing={3}>
                            {/* <br/> */}
                            <DatePicker
                                //views={['day']}
                                views={['year', 'month', 'day']}
                                label="Validity"
                                value={value}
                                onChange={(newValue) => {
                                    setValue(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} helperText={null} />}
                            />
                            <br />
                        </Stack>
                    </FormControl>


                    <FormControl sx={{ m: 1, width: 300 }}>


                        <Stack spacing={3} direction="column">
                            <LoadingButton
                                fullWidth
                                loading={isloading}
                                disabled={saveIsloading}
                                onClick={submitions}
                                variant="contained"
                            >
                                Save and continue

                            </LoadingButton>

                            <LoadingButton
                                fullWidth
                                disabled={saveIsloading}
                                onClick={handleSubmit}
                                variant="contained">Save</LoadingButton>

                        </Stack>
                    </FormControl>

                </LocalizationProvider>
            </div>
            <div>

                {status && <div>
                    <h1 className='confirm'>
                        Badge viewer
                    </h1>
                    <div className='dentro'>
                        {crachaChecks()}</div>
                </div>}


            </div>
        </div>
    );
}



