import React, { useState } from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { baseurl } from '../lib/settings';
import { format } from 'date-fns';
import Paper from '@mui/material/Paper'
import { useHistory } from 'react-router';
import Container from '@mui/material/Container';
import { createTheme } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';
import './Employee.css'
import { InputLabel } from '@mui/material';
//
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const paperStyle = {
    padding: 20,
    height: '50%',
    width: 500,
    display: "flex",
    margin: "20px auto"
}

const names = [
    'Twigg',
    "Tayanna",
    "Enhl Bonatti",
    "ST",
    'TPM-TUR',
    'Regis',
    "Fumlimpa",
    "CBE",
    "Arteba",
    'CIS',
    'Grinroad',
    'Agro horizonte',
    'Truly Nolen',
    'CBE',
    'Chelsea Group',
    'Sepri',
    'Encom',
    "Total",
    "RoadLab",
    "Bureau Veritas",
    "Belutécnica",
    "Machrent"

];



function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function AddEmployee() {
    const theme = useTheme();
    const [employee, setEmployee] = useState();
    const [personName, setPersonName] = useState([]);
    const [company, setCompany] = useState();
    const [error, setError] = useState();
    const history = useHistory();
    const [isloading, setIsLoading] = useState(false);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            typeof value === 'string' ? value.split(',') : value,
        );
        setCompany(value);
    };

    async function onSubmit() {
        setIsLoading(true);
        var date = format(new Date(), 'yyyy-MM-dd');
        const data = {
            name: employee,
            company: company,
            validate: date,
            created_at: date
        }
        baseurl.post('/api/add/employeer/', data).then((response) => {
            if (response.status === 200 || response.status === 201) {
                alert("The employee was created sucessfully!")
            }

        }).catch((error) => {
            setError(error);
            alert("An error occured while creating new Employee!")
        })
        setIsLoading(false);
    }

    async function submitions() {
        setIsLoading(true);
        var date = format(new Date(), 'yyyy-MM-dd');
        const data = {
            name: employee,
            company: company,
            validate: date,
            created_at: date
        }

        baseurl.post('/api/add/employeer/', data).then((response) => {
            if (response.status === 200 || response.status === 201) {
                history.push('/add/formation')
            }
        }).catch((error) => setError(error));
        setIsLoading(false);
    }

    return (

        <div className='main-paper'>
            <Paper elevation={3} style={paperStyle}>
                <Container component="main" maxWidth="xs">
                    <h1>New employee</h1>

                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Name"
                        name={company}
                        autoFocus
                        onChange={((e) => setEmployee(e.target.value))}
                    />
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '25ch' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                    </Box>
                    <br />
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Company</InputLabel>
                        <Select
                            fullWidth
                            id="email"
                            name="email"
                            labelId="demo-simple-select-label"
                            label="Company"

                            // multiple
                            value={personName}
                            onChange={handleChange}
                            MenuProps={MenuProps}
                        >

                            {names.map((name) => (
                                <MenuItem
                                    key={name}
                                    value={name}
                                    style={getStyles(name, personName, theme)}
                                >
                                    {name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <br />
                    <br />
                    <div className='loading'>
                        {isloading && <CircularProgress />}
                    </div>

                    <Stack spacing={13.4} direction="row">


                        {/* new button */}
                        <Button
                            onClick={submitions}
                            isLoading={submitions}
                            variant="contained">
                            Save and Add Formation
                        </Button>

                        <Button

                            onClick={onSubmit}
                            variant="contained"
                        >Save</Button>

                    </Stack>

                </Container>
            </Paper>
        </div>
    );
}
