import {
  CircularProgress,
  getLinkUtilityClass,
  LinearProgress,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import QRCode from 'qrcode';
import logo from './logo/Rlogo.png';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import photo from './perfil/pf.jpg';
import jsPDF from 'jspdf';
import PictureAsPdfIcon from '@mui/icons-material/Download';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import { baseurl } from '../../lib/settings';
import styles from './cracha.module.css';
import styles2 from './cracha2.module.css';

export function Final() {
  const [tableData, setTableData] = useState([]);
  const [tableData2, setTableData2] = useState([]);
  const { id } = useParams();
  const [src, setSrc] = useState('');
  const [isloading, setIsloading] = useState(true);
  const [dataimg, setDataimg] = useState([]);
  const [error, setError] = useState();
  const [data, setData] = useState([]);

  const handleClick = (id) => {
    /*const id = useParams*/
    History.push(`/cracha/${id}`);
  };

  /*useEffect(() => {
    fetch('http://127.0.0.1:8000/api/employeer/' + id)
      .then((data) => data.json())
      .then((data) => setDataimg(data));
  }, []);*/
  useEffect(() => {
    baseurl.get('/api/formacao/' + id).then((response) => {
      setTableData(response.data);
      verifyFormationLength(response.data);
      setIsloading(false);
    })
      .catch((error) => setError(error));
  }, []);
  QRCode.toDataURL('https://silica.speranza.co.mz/confirmation/' + id).then(
    setSrc
  );

  function verifyFormationLength(formations) {
    if(formations.length > 5){
      setTableData(formations.slice(0, 5))
      setTableData2(formations.slice(5, formations.length))
    }else{
      setTableData(formations)
    }

  }

  if (error)
    return (
      <div className="App-text">
        <h2>Sorry, could not generate the badge...</h2>
        <h3>Try again</h3>
        <Button
          variant="contained"
          onClick={(event) => {
            handleClick(event);
          }}
        >
          Generate badge
        </Button>
      </div>
    );

  if (isloading) {
    return (
      <div
        style={{
          marginTop: 150,
          position: 'absolute',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          width: '100vw',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const pdfGenerate = () => {
    var doc = new jsPDF('p', 'px', 'a4', 'false');
    doc.html(document.querySelector('#content'), {
      callback: function (pdf) {
        pdf.save('Cracha.pdf');
      },
    });
  };

  return (
    <div>
      <Stack sx={{ width: '100%' }} spacing={2} justifyContent="center">
        <Alert
          onClose={() => {}}
          action={
            <Button onClick={pdfGenerate}>
              Download {<PictureAsPdfIcon />}
            </Button>
          }
        >
          Successfully generated badge
        </Alert>
      </Stack>
      <br/>
      <div style={{
        display:'flex'
      }}>
      <div id="content" className={styles.container}>
        <header>
          <div className={styles.name}>
            <h2>{tableData.length !== 0 && tableData[0].trabalhador.name}</h2>
            {
              tableData[0].trabalhador.company !== 'Twigg' && <h1>{tableData.length !== 0 && tableData[0].trabalhador.company} - <b>Hired</b></h1> || <h1>{tableData.length !== 0 && tableData[0].trabalhador.company}</h1>
            }
          </div>
          <div>
            <div className={styles.headerText}>
              <h3>Expiry Date:</h3>
            </div>
            <img src={logo} alt="Logo" />
          </div>
        </header>
        <main>
          <div>
            <div className={styles.itemleft}>
              <p>Medical Exams</p>
              <p>{tableData.length !== 0 && tableData[0].trabalhador.validate}</p>
            </div>
            {tableData.map((dataItem, index) => (
              <div key={index} className={styles.itemleft}>
                <p>{dataItem.formacao}</p>
                <p>{dataItem.validity}</p>
              </div>
            ))}
          </div>
        </main>
        <footer>
          <p className={styles.compName2}>TWIGG</p>
          <p className={styles.companyName}>Exploration & Mining, Lda</p>
          <div className={styles.contr}>
            <img src={src} alt="QR CODE" />
          </div>
        </footer>
        <div className={styles.footercontent}>
          <img src={dataimg.image || photo} alt="Photo" />
        </div>
      </div>
      {tableData2.length > 0 ? (<div id="content" className={styles2.container}>
        {/* <header>
          <div className={styles.name}>
            <h2>{tableData.length !== 0 && tableData[0].trabalhador.name}</h2>
            {
              tableData[0].trabalhador.company !== 'Twigg' && <h1>{tableData.length !== 0 && tableData[0].trabalhador.company} - <b>Hired</b></h1> || <h1>{tableData.length !== 0 && tableData[0].trabalhador.company}</h1>
                
              }
          </div>
          <div>
            <div className={styles.headerText}>
              <h3>Expiry Date:</h3>
            </div>
            <img src={logo} alt="Logo" />
          </div>
        </header> */}
        <div className={styles2.name}>
            <h2>{tableData.length !== 0 && tableData[0].trabalhador.name}</h2>
            {/* {
              tableData[0].trabalhador.company !== 'Twigg' && <h1>{tableData.length !== 0 && tableData[0].trabalhador.company} - <b>Hired</b></h1> || <h1>{tableData.length !== 0 && tableData[0].trabalhador.company}</h1>
                
              } */}
          </div>
        <main>

          <div>
            {/* <div className={styles.itemleft}>
              <p>Medical Exams</p>
              <p>{dataimg.validate}</p>
            </div> */}

            {tableData2.map((tableData, index) => {
              return (
                <div className={styles2.itemleft}>
                  <p>{tableData.formacao}</p>
                  <p>{tableData.validity}</p>
                </div>
              );
            })}
          </div>
        </main>
        {/* <footer>
          <p className={styles.compName2}>TWIGG</p>
          <p className={styles.companyName}>Exploration & Mining, Lda</p>
          <div className={styles.contr}>
            <img src={src} alt="QR CODE" />
          </div>
        </footer> */}
        <footer>
        <div className={styles2.contr}>
            <img src={src} alt="QR CODE" />
          </div>
        </footer>
        <div className={styles2.footercontent}>
          <img src={dataimg.image || photo} alt="Photo" />
        </div>
      </div>
    ): <></>}
      </div>
      <div></div>
    </div>
  );
}
