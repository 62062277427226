import React from "react";
import { Button } from "@mui/material";
import Stack from "@mui/material/Stack";
import "./main.css";

function main() {
  return (
    
    <Stack direction="row" spacing={2} >
      <div className="card">
        <Button variant="contained"  style={{minWidth: '300px', minHeight: '200px'}} href='/quiz/1'>
          QUIZ
          </Button>
        <Button style={{minWidth: '300px', minHeight: '200px' }} variant="contained" href='/list/employee'>
          SILICA
        </Button>
      </div>
    </Stack>
  );
}

export default main;
