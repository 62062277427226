import React, {useEffect, useState} from "react";
import { baseurl } from "../../../lib/settings";
import { Button, Divider } from "@mui/material";
import { ButtonGroup } from "@mui/material";
import { Box } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';


function CrachaOvierview(props) {
  const {id, controlBadgeStatus} = props;
    const [tableData, setTableData] = useState([]);
    const [isloading, setIsloading] = useState(false);
    const [isloadingBtn, setIsloadingBtn] = useState(false);
    const [error, setError] = useState();
    const [status, setStatus] = useState(false);

    

    useEffect(() => {
        setIsloading(true);
        baseurl.get('/api/formacao/' + id.name).then((response) => {
          setTableData(response.data);
          setIsloading(false);
        })
          .catch((error) => setError(error));
          setStatus(false)
          setIsloading(false);
      }, [status]);


    const deleteSelectedFormation = (values) => {
      setIsloadingBtn(true)
        const data = {
            formation_id: values.id
        }
        baseurl.post('/api/delete/selected_formation/', data).then((resp) => {
            setIsloading(true)
            if (resp.status === 200 || resp.status === 201) {
                setStatus(true)
                setIsloadingBtn(false)
                controlBadgeStatus(values.trabalhador.id)

            }
        })
    }
     
     
  return (
    <div className="delete-form">
        <h2>Delete Formation {isloadingBtn ? <CircularProgress /> : <></>}</h2>  
        {!isloading ? <>{tableData.map((formation, index) => {
            return (
             <div className="checked-buttons">
                <div className="left-card"><p>{formation.formacao}</p></div>
                <div className="right-card"><Button key={formation.trabalhador.id} onClick={()=>deleteSelectedFormation(formation)} variant="outlined" color="error"><DeleteIcon /></Button></div>
             </div>
                ) 
             })}</> :  <CircularProgress />}   
            
    </div>
  );
}

export default CrachaOvierview;
