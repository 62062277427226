import "./App.css";
import { useParams } from 'react-router-dom';

import { useState, useEffect} from "react";
import { baseurl } from "./../../lib/settings";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
} from "recharts";


function App() {
  const [respostas, setRespostas] = useState([]);
  const [dados, setDados] = useState([{}]);
  const [counter, setCounter] = useState(0);

  const { id } = useParams();  

  const getRespostas = async () => {
    baseurl.get('api/auth/questionarioo/'+ id ).then((resp) => {
      resp.data.map((resp) => {
        var data = [
          {
            questao: resp.question,
            Sim: resp.yes,
            Não: resp.no,
          },
        ];

        setDados(data);
      });
      setRespostas(resp.data);
    });
  };

  useEffect(() => {
    getRespostas();
    const interval = setInterval(() => {
      setCounter((counter) => counter + 1);
    }, 5000);
    return () => {
      clearInterval(interval);
      if (counter === 4) {
        setCounter(0);
      }
    };
  }, [counter]);

  return (
    <>
      {dados.map((da, index) => (
        <div className="Pergunta">
          <h1 className="chart-heading">{da.questao}</h1>
        </div>
      ))}

      <ResponsiveContainer width="100%" aspect={3}>
        <BarChart
          width={400}
          height={900}
          data={dados}
          barCategoryGap={380}
          margin={{
            top: 30,
            right: 70,
            left: 70,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="2" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar width={50} dataKey="Sim" fill="#5C7BD9" />
          <Bar width={50} dataKey="Não" fill="#B90000" />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
}

export default App;

