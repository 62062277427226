import { baseurl } from "./settings";


export async function checkAuthorization()  {
    let isAdmin = undefined
    let user = undefined
    try{
        var response = await baseurl.get("api/auth/user_authentication/")
        if (response.status == 200) {
            isAdmin = response.data['is_admin']
            user = response.data['user']
        } else {
            isAdmin = false
        }
    } catch (e) {
        console.error("Filed on verify permission ", e)
        isAdmin = undefined
    }
    const data = {
        is_admin:isAdmin,
        user:user
    }
    return data
    
  }