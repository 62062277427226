import { CircularProgress } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode';
import logo from './logo/Rlogo.png';
import { Button } from '@material-ui/core';
import photo from './perfil/pf.jpg';
import jsPDF from 'jspdf';
import styles from './singleCracha.module.css';
import styles2 from './singleCracha2.module.css';

import { baseurl } from '../../lib/settings';





export function SingleCracha(props) {
  const [tableData, setTableData] = useState([]);
  const [tableData2, setTableData2] = useState([]);
  const { id } = props;
  const [src, setSrc] = useState('');
  const [isloading, setIsloading] = useState(true);
  const [dataimg, setDataimg] = useState([]);
  const [error, setError] = useState();
  const [getData, setData] = useState([]);

  const handleClick = (id) => {
    /*const id = useParams*/
    History.push(`/cracha/${id}`);
  };


  useEffect(() => {
    baseurl.get('/api/formacao/' + id).then((response) => {
      setTableData(response.data);
      verifyFormationLength(response.data);
      setIsloading(false);
    })
      .catch((error) => setError(error));
  }, []);


  QRCode.toDataURL('https://silica.speranza.co.mz/confirmation/' + id).then(
    setSrc
  );

  function verifyFormationLength(formations) {
    if(formations.length > 5){
      setTableData(formations.slice(0, 5))
      setTableData2(formations.slice(5, formations.length))
    }else{
      setTableData(formations)
    }

  }

  if (error)
    return (
      <div className="App-text">
        <h2>Sorry, could not generate the badge...</h2>
        <h3>Try again</h3>
        <Button
          variant="contained"
          onClick={(event) => {
            handleClick(event);
          }}
        >
          Generate badge
        </Button>
      </div>
    );

  /*if (dataimg.medicall == 0){
    return <p>Sem medical exame</p>
  }*/

  if (isloading) {
    return (
      <div
        style={{
          marginTop: 150,
          position: 'absolute',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          width: '100vw',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const pdfGenerate = () => {
    var doc = new jsPDF('p', 'px', 'a4', 'false');
    doc.html(document.querySelector('#content'), {
      callback: function (pdf) {
        pdf.save('Cracha.pdf');
      },
    });
  };

  var fora;
  const dateControl = tableData.map((date, index) => {
    let today = new Date();
    let validade = new Date(tableData[index].validity);
    let ms1 = today.getTime();
    let ms2 = validade.getTime();
    if (ms1 > ms2) {


      return (
        <div className="itemleft">
          <p>{tableData[index].formacao}</p>
          <p
            style={{ color: '#ff0000', fontWeight: 'bold', fontSize: '12.5px' }}
          >
            {tableData[index].validity}
          </p>
        </div>
      );
    } else {


      return (
        <div className="itemleft">
          <p>{tableData[index].formacao}</p>
          <p>{tableData[index].validity}</p>
        </div>
      );
    }
  });


  return (
    <div>
      <div style={{
        display:"flex"
      }}>
      <div id="content" className={styles.container}>
        <header>
          <div className={styles.name}>
            <h2>{tableData.length !== 0 && tableData[0].trabalhador.name}</h2>

            {
              tableData[0].trabalhador.company !== 'Twigg' && <h1>{tableData.length !== 0 && tableData[0].trabalhador.company} - <b>Hired</b></h1> || <h1>{tableData.length !== 0 && tableData[0].trabalhador.company}</h1>

            }

          </div>
          <div>
            <div className={styles.headerText}>
              <h3>Expiry Date:</h3>
            </div>
            <img src={logo} alt="Logo" />
          </div>
        </header>
        <main>
          <div>
            <div className={styles.itemleft}>
              <p>Medical Exams</p>
              <p>{tableData.length !== 0 && tableData[0].trabalhador.validate}</p>
            </div>
            {tableData.map((tableData, index) => {
              return (
                <div className={styles.itemleft}>
                  <p>{tableData.formacao}</p>
                  <p>{tableData.validity}</p>
                </div>
              );
            })}
          </div>
        </main>
        <footer>
          <p className={styles.compName2}>TWIGG</p>
          <p className={styles.companyName}>Exploration & Mining, Lda</p>
          <div className={styles.contr}>
            <img src={src} alt="QR CODE" />
          </div>
        </footer>
        <div className={styles.footercontent}>
          <img src={dataimg.image || photo} alt="Photo" />
        </div>
      </div>
      {tableData2.length > 0 ? (<div id="content" className={styles2.container}>
        {/* <header>
          <div className={styles.name}>
            <h2>{tableData.length !== 0 && tableData[0].trabalhador.name}</h2>
            {
              tableData[0].trabalhador.company !== 'Twigg' && <h1>{tableData.length !== 0 && tableData[0].trabalhador.company} - <b>Hired</b></h1> || <h1>{tableData.length !== 0 && tableData[0].trabalhador.company}</h1>
                
              }
          </div>
          <div>
            <div className={styles.headerText}>
              <h3>Expiry Date:</h3>
            </div>
            <img src={logo} alt="Logo" />
          </div>
        </header> */}
        <div className={styles2.name}>
            <h2>{tableData.length !== 0 && tableData[0].trabalhador.name}</h2>
            {/* {
              tableData[0].trabalhador.company !== 'Twigg' && <h1>{tableData.length !== 0 && tableData[0].trabalhador.company} - <b>Hired</b></h1> || <h1>{tableData.length !== 0 && tableData[0].trabalhador.company}</h1>
                
              } */}
          </div>
        <main>

          <div>
            {/* <div className={styles.itemleft}>
              <p>Medical Exams</p>
              <p>{dataimg.validate}</p>
            </div> */}

            {tableData2.map((tableData, index) => {
              return (
                <div className={styles2.itemleft}>
                  <p>{tableData.formacao}</p>
                  <p>{tableData.validity}</p>
                </div>
              );
            })}
          </div>
        </main>
        {/* <footer>
          <p className={styles.compName2}>TWIGG</p>
          <p className={styles.companyName}>Exploration & Mining, Lda</p>
          <div className={styles.contr}>
            <img src={src} alt="QR CODE" />
          </div>
        </footer> */}
        <footer>
        <div className={styles2.contr}>
            <img src={src} alt="QR CODE" />
          </div>
        </footer>
        <div className={styles2.footercontent}>
          <img src={dataimg.image || photo} alt="Photo" />
        </div>
      </div>
    ): <></>}
    <div></div>
      </div>
      <div></div>
    </div>
  );
}
