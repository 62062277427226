import React from 'react'
import DashboardApp from './DashboardApp'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import ThemeConfig from './theme'
import AppWeeklySales from './AppWeeklySales';
import AppNewUsers from './AppNewUsers';
import AppItemOrders from './AppItemOrders';

function Controller() {
  return (
    <ThemeConfig>
                <Sidebar/>
    </ThemeConfig>
  )
}

export default Controller