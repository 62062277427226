import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import App from "./App";
import { isAuthenticated } from "./lib/api";
import Employee from "./pages/Employee";
import Login from "./pages/login/login";
import AddEmployee from './form/addEmployee';
import AddFormation from './form/addFormacao';
import DashboardApp from './pages/Dashboard/DashboardApp'
import Verification from './pages/Dashboard/verification'
import Options from './form/Options';
import loginOption from './form/loginOption'
import { Final } from './pages/components/Final';
import { MultiCrachas } from './pages/components/MultiCrachas';
import { Confidentification } from './pages/Confidentification';
import Quiz from './pages/Quiz/App';
import Main from './pages/main'
import Controller from "./pages/Dashboard/Controller";
import Enter from "./pages/login/Enter"
import Update from "./pages/UPDATE/Update";
import DefaultStyle from "./pages/components/style/defaultStyle";
import MedicalExams from "./form/medicalExams";



const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Login} />
      <Route exact path="/exe789/main" component={Enter} />
      <Route path="/signup" component={() => <h1>SignUp</h1>} />
      <PrivateRoute path="/app" component={App} />
      <PrivateRoute path='/list/employee' component={() => <DefaultStyle> <Employee /> </DefaultStyle>} />
      <PrivateRoute path='/update/badge/list/employee' component={Update} />
      <PrivateRoute path="/main" component={Main} />
      <PrivateRoute path="/cracha/:id" component={Final} />
      <PrivateRoute path="/crachas" component={MultiCrachas} />
      <Route path="/confirmation/:id" component={Confidentification} />
      <PrivateRoute path='/add/option' component={Options} />
      <PrivateRoute path='/add/employee' component={() => <DefaultStyle><AddEmployee /></DefaultStyle>} />
      <PrivateRoute path='/add/formation' component={() => <DefaultStyle><AddFormation /></DefaultStyle>} />
      <PrivateRoute path='/add/medical/exams' component={() => <DefaultStyle><MedicalExams /></DefaultStyle>} />
      <PrivateRoute path='/formation/test' component={AddFormation} />
      <PrivateRoute path='/dashboard' component={DashboardApp} />
      <PrivateRoute path='/verify' component={Verification} />
      <PrivateRoute path='/admin/login' component={loginOption} />
      <PrivateRoute path='/quiz/:id' component={Quiz} />
      <PrivateRoute path='/controller' component={Controller} />
      <Route path="/" exact component={Login} />
      <Route path="*" component={() => <h1>Page not found</h1>} />
    </Switch>
  </BrowserRouter>
);


export default Routes;
