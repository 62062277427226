import React from 'react'
import Sidebar from './Sidebar2'
import ThemeConfig from './theme'

function DashboardApp() {
    return (
       <div>
           <ThemeConfig>
                <Sidebar/>
           </ThemeConfig>
               

        </div>
   
    );
}

export default DashboardApp
