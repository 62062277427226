import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { LinearProgress, Typography, Box, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Button, Alert } from '@mui/material';
import { baseurl } from '../lib/settings';
import Logo from "./../pages/components/Company/TWIGG.png";

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {new Date().getFullYear()} Speranza Lda. All rights reserved.
        </Typography>
    );
}

export default function MedicalExams() {
    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [loading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [newValidityDate, setNewValidityDate] = useState('');
    const [message, setMessage] = useState("");

    const columns = [
        { field: 'id', headerName: '#', width: 100, headerAlign: 'center', align: 'center' },
        { field: 'firstName', headerName: 'Full name', width: 200, headerAlign: 'center', align: 'center' },
        { field: 'company', headerName: 'Company', width: 200, headerAlign: 'center', align: 'center' },
        { field: 'exams_validity', headerName: 'Medical exams validity', type: 'date', width: 250, headerAlign: 'center', align: 'center' },
    ];

    useEffect(() => {

        setIsLoading(true);
        baseurl.get('/api/auth/employeer/')
            .then((response) => {
                const employees = response.data.map((employee, index) => ({
                    id: employee.id,
                    firstName: employee.name,
                    company: employee.company,
                    exams_validity: employee.validate,
                }));


                setRows(employees);
                setFilteredRows(employees);
                setIsLoading(false);
            })
            .catch((error) => {
                setError(error);
                setIsLoading(false);
                // setMessage()
            });
    }, []);

    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchText(value);

        const filtered = rows.filter((row) =>
            row.firstName.toLowerCase().includes(value)
        );
        setFilteredRows(filtered);
    };

    const handleRowClick = (params) => {
        setSelectedRow(params.row);
        setNewValidityDate(params.row.exams_validity);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedRow(null);
        setNewValidityDate('');
    };

    const handleDateChange = (event) => {
        setNewValidityDate(event.target.value);
    };

    const handleSave = () => {
        setMessage("")
        const dataPost = {
            employeeId: selectedRow.id,
            exams_validity: newValidityDate
        }
        baseurl.post('/api/employee/medical/update_validity/', dataPost).then((response) => {
            console.log(response.data)
            setMessage(response.data.message)
            setRows(rows.map(row =>
                row.id === selectedRow.id ? { ...row, exams_validity: newValidityDate } : row
            ));
            setFilteredRows(filteredRows.map(row =>
                row.id === selectedRow.id ? { ...row, exams_validity: newValidityDate } : row
            ));
            handleCloseModal();
        })
            .catch((error) => {
                setError(error);
                // setMessage(response.data.message)
            });
    };

    if (loading) return <LinearProgress />;
    if (error) return <div>Error loading data</div>;

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '80vh', padding: 5 }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 6 }}>
                    <img src={Logo} alt="Logo" style={{ width: '200px', height: 'auto' }} />
                </Box>
                <TextField
                    variant="outlined"
                    placeholder="Search by name"
                    value={searchText}
                    onChange={handleSearch}
                    sx={{ mb: 2, width: { xs: '80%', sm: '40%', md: '40%' }, }}
                />

                <div style={{ height: "100%", width: '100%', maxWidth: '900px', margin: '0 auto', overflow: 'auto' }}>
                    {message && <Alert severity={error ? 'error' : 'success'}>{message}</Alert>}
                    <br />
                    <DataGrid
                        rows={filteredRows}
                        columns={columns}
                        disableColumnMenu
                        onRowClick={handleRowClick}
                    />
                </div>

                <Dialog open={openModal} onClose={handleCloseModal}>
                    <DialogTitle>Update Medical Exam Validity&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</DialogTitle>
                    <DialogContent>
                        <Typography variant="h6">Details</Typography>
                        <Typography><strong>Full Name:</strong> {selectedRow?.firstName}</Typography>
                        <Typography><strong>Company:</strong> {selectedRow?.company}</Typography>
                        <Typography>
                            <strong>Current Validity Date:</strong> {new Date(selectedRow?.exams_validity).toLocaleDateString()}
                        </Typography>
                        <TextField
                            type="date"
                            variant="outlined"
                            label="Update with new Validity Date"
                            value={newValidityDate}
                            onChange={handleDateChange}
                            fullWidth
                            margin="normal"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseModal}>Cancel</Button>
                        <Button onClick={handleSave} color="primary">Update</Button>
                    </DialogActions>
                </Dialog>
            </Box>
            <Copyright sx={{ mt: 8, mb: 4 }} />
        </>
    );
}
