import {
  CircularProgress,
  getLinkUtilityClass,
  LinearProgress,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import QRCode from 'qrcode';
import logo from '../logo/Rlogo.png';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import photo from '../perfil/pf.jpg';
import jsPDF from 'jspdf';
import PictureAsPdfIcon from '@mui/icons-material/Download';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import { baseurl } from '../../../lib/settings';
import styles from '../cracha.module.css';


export function CrachaCheck({ ids }) {
  const [tableData, setTableData] = useState([]);
  //const { id } = useParams();
  const id = ids.name

  const [src, setSrc] = useState('');
  const [isloading, setIsloading] = useState(true);
  const [dataimg, setDataimg] = useState([]);
  const [error, setError] = useState();
  const [data, setData] = useState([]);
  const [controlBadge, setControlBadge] = useState(false);
  const history = useHistory();


  const handleClick = (id) => {
    setControlBadge(true)
  };
  useEffect(() => {
    baseurl.get('/api/formacao/' + id).then((response) => {
      setTableData(response.data);
      setIsloading(false);
    })
      .catch((error) => setError(error));
    setControlBadge(false);
  }, [controlBadge]);
  QRCode.toDataURL('https://silica.speranza.co.mz/confirmation/' + id).then(
    setSrc
  );

  if (error)
    return (
      <div className="App-text">
        <h2>Desculpe não foi possível gerar o crachá...</h2>
        <h3>Tente novamente</h3>
        <Button
          variant="contained"
          onClick={(event) => {
            handleClick(event);
          }}
        >
          Gerar crachá
        </Button>
      </div>
    );

  /*if (dataimg.medicall == 0){
    return <p>Sem medical exame</p>
  }*/

  if (isloading) {
    return (
      <div
        style={{
          marginTop: 150,
          position: 'absolute',
          alignItems: 'center',
          display: 'block',
          justifyContent: 'center',
          width: '100vw',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const pdfGenerate = () => {
    var doc = new jsPDF('p', 'px', 'a4', 'false');
    doc.html(document.querySelector('#content'), {
      callback: function (pdf) {
        pdf.save('Cracha.pdf');
      },
    });
  };

  /* Control Training Validate */
  var fora;
  const dateControl = tableData.map((date, index) => {
    let today = new Date();
    let validade = new Date(tableData[index].validity);
    let ms1 = today.getTime();
    let ms2 = validade.getTime();
    if (ms1 > ms2) {
      fora = 1;
      return (
        <div className="itemleft">
          <p>{tableData[index].formacao}</p>
          <p
            style={{ color: '#ff0000', fontWeight: 'bold', fontSize: '12.5px' }}
          >
            {tableData[index].validity}
          </p>
        </div>
      );
    } else {
      fora = 2;
      return (
        <div className="itemleft">
          <p>{tableData[index].formacao}</p>
          <p>{tableData[index].validity}</p>
        </div>
      );
    }
  });

  /* Control Training Validate */
  return (
    <div>
      <div id="content" className={styles.container}>
        <header>
          <div className={styles.name}>
            <h2>{tableData.length !== 0 && tableData[0].trabalhador.name}</h2>
            <h1>{tableData.length !== 0 && tableData[0].trabalhador.company}</h1>
          </div>
          <div>
            <div className={styles.headerText}>
              <h3>Expiry Date:</h3>
            </div>
            <img src={logo} alt="Logo" />
          </div>
        </header>
        <main>
          <div>
            <div className={styles.itemleft}>
              <p>Medical Exams</p>
              <p>{tableData.length !== 0 && tableData[0].trabalhador.validate}</p>
            </div>
            {tableData.map((tableData, index) => {
              return (
                <div className={styles.itemleft}>
                  <p>{tableData.formacao}</p>
                  <p>{tableData.validity}</p>
                </div>
              );
            })}
          </div>
        </main>
        <footer>
          <p className={styles.compName2}>TWIGG</p>
          <p className={styles.companyName}>Exploration & Mining, Lda</p>
          <div className={styles.contr}>
            <img src={src} alt="QR CODE" />
          </div>
        </footer>
        <div className={styles.footercontent}>
          <img src={dataimg.image || photo} alt="Photo" />
        </div>
      </div>
      <div></div>
    </div>
  );
}
