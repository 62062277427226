import React, { useState, useEffect } from "react";
import "../App.css";
import { Link } from "react-router-dom";
import { LinearProgress } from "@material-ui/core";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import TWIGG from "./components/Company/TWIGG.png";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { createTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { DataGrid, GridToolbarFilterButton, } from "@mui/x-data-grid";
import "./components/companylogo.css";
import "../App.css";
import { baseurl } from "../lib/settings";
import Typography from "@mui/material/Typography";
import "./Employee.css";


function Copyright(props) {
  const history = useHistory();

  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/"></Link>
      {" SPERANZA "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}


function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}



const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(0.5, 0.5, 0),
      justifyContent: "space-between",
      display: "flex",
      alignItems: "flex-start",
      flexWrap: "wrap",
    },
    textField: {
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
      margin: theme.spacing(1, 0.5, 1.5),
      "& .MuiSvgIcon-root": {
        marginRight: theme.spacing(0.5),
      },
      "& .MuiInput-underline:before": {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
  }),
  { defaultTheme }
);

function QuickSearchToolbar(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <GridToolbarFilterButton
          style={{ visibility: props.value ? "visible" : "hidden" }}
        />
      </div>
      <TextField
        variant="standard"
        style={{ width: 150 }}
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? "visible" : "hidden" }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </div>
  );
}


QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};


export default function Employee() {
  const options = {
    filterType: "checkbox",
  };
  const history = useHistory();

  const [rows, setRows] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);

  const [error, setError] = useState();
  const [loading, setIsloading] = useState(true);

  const [searchText, setSearchText] = React.useState("");
  const [ros, setRos] = React.useState(rows.ros);
  const [valor, setValor] = useState();
  const token = localStorage.getItem('token')



  const handleClick = (event, row) => {
    const id = row.id;
    history.push(`/cracha/${id}`);
  };

  const columns = [
    {
      field: "id",
      headerName: "#",
      width: 100,
    },
    {
      field: "name",
      headerName: "Name",
      filter: true,
      width: 220,
    },

    {
      field: "Option",
      width: 200,
      renderCell: (cellValues) => {
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={(event) => {
              handleClick(event, cellValues);
            }}
          >
            Generate badge
          </Button>
        );
      },
    },
  ];


  function onSubmit() {
    history.push('/admin/login')
  }

  // Search Box

  const requestSearch = (searchValue) => {
    setValor(searchValue);
    setSearchText(searchValue);
    /*const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
  const filteredRows = rows.ros.filter((row) => {
    return Object.keys(row).some((field) => {
      return searchRegex.test(row[field].toString());
    });
  });
  setRos(filteredRows);*/
  };

  React.useEffect(() => {
    setRos(rows.ros);
  }, [rows.ros]);

  // Search Box

  useEffect(() => {
    baseurl
      .get("/api/auth/employeer/")
      .then((response) => {
        setRows(response.data);
        setIsloading(false);
      })
      .catch((error) => setError(error));
  }, []);



  if (error)
    return (
      <div className="App-text">
        <h2>Ops! Não foi possível processar os dados</h2>
      </div>
    );

  if (loading)
    return (
      <div>
        <LinearProgress />
      </div>
    );



  return (
    <>


      <div>

        <div className="cont">
          <img src={TWIGG} alt="logo" id="logo" />
          <h2>List of Employees</h2>

          {selectedIds.length > 0 && (
            <Button variant="contained" color="primary">
              <Link
                to={{
                  pathname: "/crachas",
                  state: { ids: selectedIds },

                }}
              >
                generate badges
              </Link>

            </Button>

          )}
        </div>
        <div className="App-table">
          <DataGrid
            filterModel={{
              items: [
                {
                  columnField: "name",
                  operatorValue: "contains",
                  value: valor,
                },
              ],
            }}
            components={{ Toolbar: QuickSearchToolbar }}
            rows={rows}
            columns={columns}
            pageSize={10}
            checkboxSelection
            disableSelectionOnClick
            onSelectionModelChange={(id) => {
              setSelectedIds(id);
            }}
            componentsProps={{
              toolbar: {
                value: searchText,
                onChange: (event) => requestSearch(event.target.value),
                clearSearch: () => requestSearch(""),
              },
            }}
          />
        </div>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </div>


    </>
  );


}


