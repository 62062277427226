import React, { useEffect, useState } from "react";
import { AccountCircle } from "@mui/icons-material";
import { Avatar, Chip, Drawer, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { AppBar, Box, CssBaseline, Divider, IconButton, List, ListItem, Toolbar, Typography } from "@mui/material";
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import BadgeIcon from '@mui/icons-material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import { CircularProgress } from "@mui/material";


import logo from '../logo/Rlogo.png'
import { checkAuthorization } from "../../../lib/auth";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


export default function DefaultStyle({ children }) {
  const [open, setOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [user, setUser] = useState({
    id: 0,
    username: '',
    email: '',
    full_name: '',
    is_superuser: false,
  });
  const [authLoading, setAuthLoading] = useState(false);
  const history = useHistory();

  function toggleDrawer(newOpen) {
    setOpen(newOpen);
  };
  useEffect(() => {
    checkPermission();
  }, [])

  async function checkPermission() {
    setAuthLoading(true);
    var resp = await checkAuthorization();
    setIsAdmin(resp.is_admin);
    setUser(resp.user)
    setAuthLoading(false);

  }

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={() => toggleDrawer(false)}>
      <br />
      <br />
      <br />
      <Divider />
      <List>
      <ListItem key={1} disablePadding>
          <ListItemButton onClick={() => history.push('/list/employee')}>
            <ListItemIcon>
              <MedicalServicesIcon />
            </ListItemIcon>
            <ListItemText primary={"Badges"} />
          </ListItemButton>
        </ListItem>
        <ListItem key={2} disablePadding>
          <ListItemButton onClick={() => history.push('/add/employee')}>
            <ListItemIcon>
              <PersonAddAlt1Icon />
            </ListItemIcon>
            <ListItemText primary={"Register employee"} />
          </ListItemButton>
        </ListItem>
        <ListItem key={3} disablePadding>
          <ListItemButton onClick={() => history.push('/add/formation')}>
            <ListItemIcon>
              <BadgeIcon />
            </ListItemIcon>
            <ListItemText primary={"Manage Badge"} />
          </ListItemButton>
        </ListItem>
        <ListItem key={4} disablePadding>
          <ListItemButton onClick={() => history.push('/add/medical/exams')}>
            <ListItemIcon>
              <MedicalServicesIcon />
            </ListItemIcon>
            <ListItemText primary={"Medical Exams"} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      <CssBaseline />
      <AppBar>
        <Toolbar>
          {/* <IconButton
                    size="small"
                    // edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    sx={{ mr: 2 }}
                >
                    <img src="" alt="logo"/>
                </IconButton> */}
          {authLoading ? (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              disabled
              sx={{ mr: 2 }}
            >
              <CircularProgress color="inherit" size={20} />
            </IconButton>) : (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ mr: 2 }}
              disabled={!isAdmin}
              onClick={() => toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: 'none', sm: 'block' } }}
          >
            Silica
          </Typography>
          {/* <Search>
                    <SearchIconWrapper>
                    <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                    placeholder="Search…"
                    inputProps={{ 'aria-label': 'search' }}
                    />
                </Search> */}
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>


            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              // aria-controls={menuId}
              aria-haspopup="true"
              // onClick={handleProfileMenuOpen}
              disabled
              color="inherit"
            >
              <Chip avatar={<Avatar>{user.full_name.substring(0, 1)}</Avatar>} label={user.full_name} />
            </IconButton>
          </Box>

        </Toolbar>


      </AppBar>
      <Toolbar />
      {children}
      <Drawer open={open} onClose={() => toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </>
  )
}